import { Divide, SendIcon, Trash2 } from 'lucide-react';
import { Button } from './ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card"
import { Textarea } from './ui/textarea';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Preloader from './preloader';

interface Question {
  text: string;
  type: string;
}

interface Answer {
  text: string;
  type: string;
}

export default function ChatFrame(params: { botName: string, botDescription: string, botNode: string, botImage: string }) {

  const inputField = useRef<HTMLTextAreaElement>(null);
  const submitButton = useRef<HTMLButtonElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<number>(1);
  const [formal, setFormal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [blocks, setBlock] = useState<(Answer | Question)[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [clearing, setClearing] = useState<boolean>(false);

  const fetchNode = () => {
    fetch(`${process.env.REACT_APP_API_URL}/inoovum/chatbot/node/${params.botNode}`, { method: 'GET', redirect: 'follow', credentials: 'include' })
      .then(response => response && response.json())
      .then(result => {
        if (result.status && result.status === 'success' && result.mode) {
          setMode(result.mode);
        }
        if (result.status && result.status === 'success' && result.formal) {
          setFormal(result.formal);
        }
      })
      .catch(error => console.log('error', error));
  }

  const sendRequest = () => {
    setFetching(true);
    const formData = new FormData();
    formData.append("prompt", message);
    formData.append("mode", mode.toString());
    // formData.append("mode", "1");
    formData.append("nodeIdentifier", params.botNode);

    fetch(`${process.env.REACT_APP_API_URL}/inoovum/chatbot/handle`, { method: 'POST', redirect: 'follow', body: formData, credentials: 'include' })
      .then(response => response && response.json())
      .then(result => {
        console.log(result);
        const newBlock: Answer = { text: result, type: 'answer' };
        setBlock(prevBlocks => [...prevBlocks, newBlock]);
        setFetching(false);
        setMessage('');
        if (inputField.current) {
          inputField.current.focus();
        }
      })
      .catch(error => console.log('error', error));
  }

  const clearChat = () => {
    setClearing(true);
    fetch(`${process.env.REACT_APP_API_URL}/inoovum/chatbot/flush`, { method: 'POST', redirect: 'follow', credentials: 'include' })
      .then(response => response && response.json())
      .then(result => {
        console.log(result);
        if (result && result.status && result.status === 'success') {
          setBlock([]);
          setMessage('');
          setClearing(false);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleClick = () => {
    const newBlock: Question = { text: message, type: 'question' };
    setBlock(prevBlocks => [...prevBlocks, newBlock]);
    sendRequest();
  }

  const preventEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitButton.current?.click();
    }
  }

  const setBlocksToLocalStorage = () => {
    const domain = window.location.hostname;
    localStorage.setItem(`blocks-${domain}`, JSON.stringify(blocks));
  }

  const getBlocksFromLocalStorage = () => {
    const domain = window.location.hostname;
    const blocks = localStorage.getItem(`blocks-${domain}`);
    return blocks ? JSON.parse(blocks) : [];
  }

  useEffect(() => {
    fetchNode();
    const previousBlocks = getBlocksFromLocalStorage();
    if (previousBlocks && previousBlocks.length > 0) {
      previousBlocks.forEach((block: Answer | Question) => {
        setBlock(prevBlocks => [...prevBlocks, block]);
      });
    }
    window.addEventListener('keydown', preventEnter);
    inputField.current?.focus();
  }, []);

  useEffect(() => {
    setBlocksToLocalStorage();
  }, [blocks]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [blocks, scrollRef]);

  return (
    <Card className="w-full lg:min-w-[450px] lg:max-w-[450px] mb-5">
      <CardHeader>
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-3">
            {params.botImage !== '' && <img src={params.botImage} alt={params.botName} className="w-14 h-14 rounded-full" />}
            <div className="flex flex-col">
              <CardTitle>{params.botName !== '' ? params.botName : 'Genius!'}</CardTitle>
              {params.botDescription && <CardDescription>{params.botDescription}</CardDescription>}
            </div>
          </div>
          {blocks && blocks.length > 0 &&
            <Button variant="outline" title="Chat löschen" className="mb-3" size="sm" onClick={clearChat}>
              <Trash2 />
            </Button>
          }
        </div>
      </CardHeader>
      {blocks && blocks.length > 0 &&
        <CardContent ref={scrollRef} className="overflow-y-scroll max-h-[70vh] lg:max-h-[calc(100vh-500px)]">
          <div className="flex flex-col gap-3">
            {blocks.map((block: Answer | Question, index) => (
              <div key={index} className={`flex flex-row ${block.type === 'answer' ? 'justify-start me-10' : 'justify-end ms-10'}`}>
                <div className="flex flex-col">
                  <div className={`text-xs text-gray-500 font-semibold${block.type === 'answer' ? ' text-start ms-1' : ' text-end me-1'}`}>{block.type === 'answer' ? (params.botName !== '' ? params.botName : 'Genius!') : (formal ? 'Sie' : 'Du')}</div>
                  <div dangerouslySetInnerHTML={{ __html: block.text }} key={index} className={`${block.type === 'answer' ? 'rounded-md bg-gray-100 p-2 w-fit' : 'rounded-md bg-primary text-primary-foreground p-2 w-fit break-words'}`} />
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      }
      {fetching && <Preloader />}
      {clearing &&
        <div className="flex flex-col items-center justify-center gap-2 w-full pt-6">
          <Preloader />
          <span>Chatverlauf wird gelöscht ...</span>
        </div>
      }
      <CardFooter>
        <div className="flex flex-row items-center gap-2 w-full pt-6">
          <Textarea ref={inputField} placeholder="Nachricht" value={message} className={`text-sm lg:text-base${fetching || clearing ? ' pointer-events-none opacity-50' : ''}`} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)} />
          <Button ref={submitButton} variant="link" onClick={handleClick} className={fetching || clearing ? 'pointer-events-none opacity-50' : ''}>
            <SendIcon />
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
